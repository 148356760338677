function checkArray(key) {
  let arr = [0, 1]
  let index = arr.indexOf(key)
  return index > -1
}

const permission = {
  inserted(el, binding) {
    let permission = binding.value // 获取到 v-permission的值
    if (permission) {
      let hasPermission = checkArray(permission)
      if (!hasPermission) {
        // 没有权限 移除Dom元素
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
}

export default permission