import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/login/login'
import Store from '@/store';
import NProgress from 'nprogress';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path === '/') {
    const req_code = sessionStorage.getItem('req_code')
    sessionStorage.clear()
    req_code && sessionStorage.setItem('req_code', req_code)
    Store.commit('changeLoading', false)
  }
  if (to.path === '/admin') {
    if (!sessionStorage.getItem('token')) {
      next('/')
    }
  }
  next()
})

router.afterEach(() => {
  NProgress.done();
});

export default router
