import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取用户组列表
  getUsers: params => request.get(`${base.baseUrl}/base/menu-permission/getUsersByPosition`, { params }),
  // 获取菜单权限树
  getMenus: params => request.get(`${base.baseUrl}/base/menu-permission/getMenuPermissionsByUser`, { params }),
  // 勾选权限
  add: params => request.post(`${base.baseUrl}/base/menu-permission/insertMenuPermission`, params),
  // 取消勾选权限
  del: params => request.post(`${base.baseUrl}/base/menu-permission/deleteMenuPermission`, params)
}
