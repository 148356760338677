<template>
  <div class="admin-menus">
    <div class="options">
      <div class="option" v-for="item in menusOption" :key="item.id" @click="openPage(item)">{{ item.title }}</div>
    </div>
    <div class="lists">
      <div class="option"
           :class="item.type"
           v-for="item in listOption"
           :key="item.id"
           @click="openPage(item)"><i class="el-icon-finished" v-if="item.type === 'list'" />{{ item.title }}</div>
    </div>
    <p class="no-data" v-if="!menus.length">暂无菜单</p>
  </div>
</template>

<script>
export default {
  props: {
    menus: Array
  },
  computed: {
    menusOption() {
      return this.menus.filter(i => !i.type)
    },
    listOption() {
      return this.menus.filter(i => i.type)
    }
  },
  methods: {
    openPage(item) {
      this.$emit('open', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "AdminMenus";
</style>
