import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.post(`${base.baseUrl}/base/supplier/getSupplier`, params),
  // 新增
  add: params => request.post(`${base.baseUrl}/base/supplier/insertSupplier`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/base/supplier/updateSupplier`, params),
  // 删除
  del: id => request.post(`${base.baseUrl}/base/supplier/deleteSupplier`, {id}),
}
