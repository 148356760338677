const isType = (typeString, typeContent) => Object.prototype.toString.call(typeContent) === `[object ${typeString}]`
export const isVoid = value => value === undefined || value === "" || value === null

export default {
  /**
   * 阻塞函数 配合 async/await 进行阻塞
   * @param time
   * @returns {Promise<unknown>}
   */
  sleep(time) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  },

  doEmptyValOfObj(obj) {
    let result = {};
    Object.entries(obj).forEach(i => {
      if (i[1] !== null &&  i[1] !== "" &&  i[1] !== undefined && Object.prototype.toString.call(i[1]) !== "[object Array]") {
        result[i[0]] = i[1];
      }
      if (Object.prototype.toString.call(i[1]) === "[object Array]" && i[1].length > 0) {
        result[i[0]] = i[1];
      }
    });
    return result;
  },

  /**
   * 嵌套对象转 FormData
   * @param obj 待转对象
   * @returns {FormData}
   */
  dealFormData(obj) {
    let formData = new FormData();

    /**
     * 添加 FormData 数据
     * @param data 待递归数据
     * @param prevKey 上一个添加 FormData 数据的 key
     */
    const appendFormData = function (data, prevKey = "") {
      Object.keys(data).map(key => {
        if (!isVoid(data[key])) {
          if (!isType("Array", data[key]) && !isType("Object", data[key])) {
            if (isType("Object", data)) {
              formData.append(`${prevKey ? prevKey + "." : ""}${key}` || key, data[key])
            } else {
              formData.append(`${prevKey}${key}`, data[key])
            }
          } else if (isType("Array", data[key]) && data[key].length) {
            data[key].map((arrItem, arrIndex) => {
              appendFormData({[`${prevKey}${prevKey ? "." + key : key}[${arrIndex}]`]: arrItem})
            })
          } else if (isType("Object", data[key])) {
            appendFormData(data[key], prevKey || key)
          }
        }
      })
    }

    appendFormData(obj)
    return formData;
  }
}
