import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.get(`${base.baseUrl}/base/customer-staffuser/getCustomerStaffUsers`, { params }),
  // 获取列表
  getDetail: userId => request.get(`${base.baseUrl}/base/customer-staffuser/getCustomerStaffUser/${userId}` ),
  // 新增
  add: params => request.post(`${base.baseUrl}/base/customer-staffuser/insertCustomerStaffUser`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/base/customer-staffuser/updateCustomerStaffUser`, params),
  // 删除
  del: userId => request.get(`${base.baseUrl}/base/customer-staffuser/deleteCustomerStaffUser/${userId}`),
  // 解绑
  unbind: userId => request.post(`${base.baseUrl}/base/customer-staffuser/untieDeviceNumber`, {staffUserId: userId}),
}
