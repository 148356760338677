import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.get(`${base.baseUrl}/platform/internal-staffuser/getInternalStaffusers`, { params }),
  // 获取详情
  getDetail: id => request.get(`${base.baseUrl}/platform/internal-staffuser/getInternalStaffuser/${id}`),
  // 新增
  add: params => request.post(`${base.baseUrl}/platform/internal-staffuser/insertInternalStaffuser`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/platform/internal-staffuser/updateInternalStaffuser`, params),
  // 删除
  del: id => request.get(`${base.baseUrl}/platform/internal-staffuser/deleteInternalStaffuser/${id}`),
}