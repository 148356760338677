import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取验证码
  getCode: randomCode => request.get(`${base.baseUrl}/login/getVerificationCode?randomCode=${randomCode}`),
  // 获取用户信息
  getUserInfo: params => request.get(`${base.baseUrl}/login/getBaseUserInfo`, { params }),
  // 登录
  login: params => request.post(`${base.baseUrl}/login/loginCustomer`, params),
  // 修改密码
  updatePassword: params => request.post(`${base.baseUrl}/login/updatePass`, params)
}
