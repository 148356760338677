const menus = [
  {
    id: 0,
    name: "base",
    title: "基础资料",
    children: [
      {id: 3, name: "department_add", title: "部门设置", component: () => import("@/views/base/department-set/department-set")},
      {id: 1, name: "product_add", title: "产品资料", component: () => import("@/views/base/product-edit/product-edit")},
      {id: 2, name: "user_add", title: "用户资料", component: () => import("@/views/base/user-edit/user-edit")},
      {id: 4, name: "process_add", title: "流程设置", component: () => import("@/views/base/process-set/process-set")},
      {id: 5, name: "company_add", title: "单位设置", component: () => import("@/views/base/unit-set/unit-set")},
      {id: 6, name: "supplier_add", title: "供应商设置", component: () => import("@/views/base/supplier-set/supplier-set")},
      {id: 7, name: "auth_manage", title: "菜单权限", component: () => import("@/views/base/auth-manage/auth-manage")},
      {id: 8, name: "product_add", title: "产品列表", component: () => import("@/views/base/product-list/product-list"), type: "list"},
      {id: 9, name: "user_add", title: "用户列表", component: () => import("@/views/base/user-list/user-list"), type: "list"},
      {id: 10, name: "department_add", title: "部门列表", component: () => import("@/views/base/department-list/department-list"), type: "list"},
      {id: 11, name: "supplier_add", title: "供应商列表", component: () => import("@/views/base/supplier-list/supplier-list"), type: "list"},
    ]
  },
  {
    id: 1,
    name: "production-task",
    title: "生产任务",
    children: [
      {id: 12, name: "production_add", title: "染色生产单", component: () => import("@/views/production/production-order/production-order")},
      {id: 13, name: "progress_add", title: "染色跟单", component: () => import("@/views/production/production-process/production-process")},
      // {id: 19, name: "production_stock", title: "染色回库表", component: () => import("@/views/production/color-back-report/color-back-report"), type: "report"},
    ]
  },
  {
    id: 2,
    name: "inventory-management",
    title: "库存管理",
    children: [
      {id: 14, name: "incoming_add", title: "来胚单", component: () => import("@/views/stock/incoming-order/incoming-order")},
      {id: 15, name: "incoming_add", title: "来胚单列表", component: () => import("@/views/stock/incoming-order-list/incoming-order-list"), type: "list"},
    ]
  },
  {
    id: 3,
    name: "management-reports",
    title: "管理报表",
    children: [
      {id: 16, name: "production_report", title: "生产报表", component: () => import("@/views/report/production-report/production-report")},
      {id: 17, name: "stock_list", title: "库存结存表", component: () => import("@/views/report/stock-list/stock-list"), type: "report"},
      {id: 18, name: "stock_details", title: "出入库明细", component: () => import("@/views/report/stock-details/stock-details"), type: "report"},
      // {id: 18, name: "dyeing_efficiency", title: "染厂效率表", component: () => import("@/views/report/dyeing-efficiency-report/dyeing-efficiency-report"), type: "report"},
    ]
  },
  // {
  //   id: 100,
  //   name: "platform_manage",
  //   title: "平台管理",
  //   children: [
  //     {id: 100, name: "merchant_add", title: "商家新增", component: () => import("@/views/platform-manage/merchant-add/merchant-add")},
  //     {id: 101, name: "merchant_manage", title: "商家管理", component: () => import("@/views/platform-manage/merchant_manage/merchant_manage")},
  //     {id: 102, name: "user_manage", title: "用户管理", component: ''}
  //   ]
  // }
]

export default {
  // 获取父菜单
  getNav(rules) {
    // console.log(rules)
    if (!rules || !rules.length) return false;
    return rules
      .filter(i => menus.findIndex(menu => menu.name === i.name) >= 0)
      .map(i => ({...i, title: menus.find(menu => menu.name === i.name).title}))
    // return menus
  },

  // 获取子菜单
  getMenus(method, rules, role) {
    // console.log(method, rules, role)
    if (role === 'customer' || role === 'internalstaffuser') {
      return menus.find(i => i.name === method).children.filter(i => i.component)
    }

    if (!rules || !rules.length) return [];
    return menus
      .find(i => i.name === method).children
      .filter(i => rules.findIndex(rule => rule.childMenuName === i.name) >= 0)
      .map(i => ({...i, auth: rules.find(rule => rule.childMenuName === i.name).childMenuPermissions}))
      .filter(i => i.auth.findIndex(auth => auth.delflag) >= 0 && i.component)
    // return menus.find(i => i.name === method).children
  }
}
