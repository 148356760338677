<template>
  <div class="admin-header">
    <div class="left-wrap">
      <div class="logo">织染星球</div>
      <div class="department" v-if="$store.state.isGd">
        <span><span style="font-size: 12px;">部门：</span>{{ departmentName }}</span>
        <el-button type="danger" size="mini" class="change-btn" @click="showChooseDepartment = true">切换部门</el-button>
      </div>
    </div>
    <div class="right-wrap">
      <div style="margin-right: 30px;" v-if="$store.state.userinfo.name">
        欢迎您，{{ $store.state.userinfo.name }}。
      </div>
      <div class="options">
        <span class="option-lint" @click="updatePassword">修改密码</span>
        <span class="divide"></span>
      </div>
      <el-popconfirm title="确定退出吗？"
                     confirmButtonText="退出"
                     cancelButtonText="取消"
                     cancelButtonType="info"
                     @confirm="logout">
        <template #reference>
          <span class="option-lint">退出登录</span>
        </template>
      </el-popconfirm>
    </div>
    <teleport to="body">
      <div class="choose-department" v-if="$store.state.isGd && showChooseDepartment">
        <h2 class="title">选择跟单部门</h2>
        <div class="item"
             v-for="department in $store.state.gdDepartments"
             :key="department.id"
             @click="changeDepartment(department.id)">
          <p class="name">{{ department.name }}
            <span v-if="$store.state.departmentId === department.id" class="current">当前</span>
          </p>
          <i class="el-icon-right i-into"></i>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showChooseDepartment: false
    }
  },

  computed: {
    departmentName() {
      return this.$store.state.gdDepartments.find(i => i.id === this.$store.state.departmentId)?.name || ""
    }
  },

  watch: {
    "$store.state.isGd": {
      handler: function () {
        if (this.$store.state.isGd && !this.$store.state.departmentId) {
          this.showChooseDepartment = true
        }
      },
      immediate: true
    }
  },

  methods: {
    changeDepartment(id) {
      this.$store.commit("setDepartmentId", id)
      this.showChooseDepartment = false
    },
    updatePassword() {
      this.$emit("updatePassword")
    },
    logout() {
      this.$store.dispatch("logout")
      this.$router.push("/")
    }
  }
}
</script>

<style scoped lang="scss">
@import "AdminHeader";
</style>
