import Common from './modules/common' // 公共接口
import Base from './modules/base' // 基础资料
import Production from './modules/production' // 生产任务
import Stock from './modules/stock' // 库存管理
import Report from './modules/report' // 管理报表
import PlatformManage from "./modules/platform-manage" // 平台管理

export default {
  Common,
  Base,
  Production,
  Stock,
  Report,
  PlatformManage
}
