<template>
  <div class="login">
    <div class="container">
      <el-card>
        <div class="login-card">
          <div class="card-title">
            <img :src="require('@/assets/images/login-logo.png')" alt="" class="logo">
            <span>织染星球</span>
          </div>
          <el-form :model="form"
                   :rules="formRules"
                   ref="loginForm"
                   status-icon
                   hide-required-asterisk
                   label-width="60px"
                   @keyup.enter="login"
                   @submit.prevent>
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" autocomplete="off" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="form.password" show-password autocomplete="off" size="medium"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <div class="code-wrap">
                <el-input v-model="form.code" maxlength="4" size="medium"></el-input>
                <div class="img-code" v-loading="!imgCode"><img :src="imgCode" alt="" v-if="imgCode" @click="getCode"></div>
              </div>
            </el-form-item>
          </el-form>
          <div class="btn-group">
            <el-button type="danger" size="medium" @click="login" :loading="isLoading">登录</el-button>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        randomCode: Date.now(),
        username: "",
        password: "",
        code: ""
      },

      formRules: {
        username: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        password: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ],
        code: [
          {required: true, message: "请输入验证码", trigger: "blur"},
          {min: 4, max: 4, message: "验证码长度不正确", trigger: "blur"}
        ]
      },

      isLoading: false,

      imgCode: "",
      isRefreshCode: true,
      refreshTimer: undefined
    }
  },

  mounted() {
    this.getCode()
    if (+sessionStorage.getItem("req_code") === 301) {
      this.$message.error("登录过期，请重新登录")
      setTimeout(() => {
        sessionStorage.removeItem("req_code")
      }, 100)
    }
  },

  beforeUnmount() {
    this.isRefreshCode = false
  },

  methods: {
    async getCode() {
      if (this.refreshTimer) clearTimeout(this.refreshTimer);
      if (this.isRefreshCode) {
        this.imgCode = ""
        const imgCode = await this.$api.Common.User.getCode(this.form.randomCode).catch(() => {})
        if (imgCode) {
          this.imgCode = imgCode
          this.refreshTimer = setTimeout(this.getCode, 50000)
        }
      }
    },

    login() {
      this.$refs["loginForm"].validate(async valid => {
        if (valid) {
          const sendData = {
            randomCode: `code_${this.form.randomCode}`,
            username: this.form.username,
            userpwd: this.form.password,
            code: this.form.code
          }
          this.isLoading = true
          const res = await this.$api.Common.User.login(sendData).catch(() => {})
          this.isLoading = false
          if (res?.code) {
            if (res.code === 201) {
              this.$message.error(res?.msg || "验证码错误")
            } else if (res.code === 200) {
              this.isRefreshCode = false
              this.$store.dispatch("login", res.data.token)
              localStorage.setItem("token", res.data.token)
              localStorage.setItem("expire", res.data.expire)
              localStorage.setItem("expire_in", res.data.expire_in)
            }
          } else {
            this.$message.error(res?.msg || "登录错误")
          }
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
@import "login";
</style>
