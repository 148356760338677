import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: () => request.get(`${base.baseUrl}/base/company/getCompanys`),
  // 新增
  add: params => request.post(`${base.baseUrl}/base/company/insertCompany`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/base/company/updateCompany`, params),
  // 删除
  del: id => request.get(`${base.baseUrl}/base/company/deleteCompany/${id}`),
}
