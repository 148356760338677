<template>
  <div class="admin-nav">
    <div class="nav-item"
         :class="{'active': current === item.name}"
         v-for="item in rules"
         :key="item.id"
         @click="changeItem(item)">{{ item.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
      default: () => ""
    },
    rules: Array
  },
  methods: {
    changeItem(item) {
      this.$emit('change', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "AdminNav";
</style>
