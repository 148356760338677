import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DayJS from 'dayjs'
import "./assets/scss/element-variables.scss"
import element from "element-plus";
import locale from 'element-plus/lib/locale/lang/zh-cn'
import directive from "@/directives";
import "./assets/scss/nprogress.css";
import api from './api'
import GetList from "@/libs/getList";
import utils from './libs/utils'

const app = createApp(App)

app.config.globalProperties.$api = api
app.config.globalProperties.$getList = GetList
app.config.globalProperties.$utils = utils
app.config.globalProperties.$day = DayJS
app.config.globalProperties.$showLoading = () => {store.commit('changeLoading', true)}
app.config.globalProperties.$hideLoading = () => {store.commit('changeLoading', false)}

app.use(store)
  .use(router)
  .use(directive)
  .use(element, {locale})

const vm = app.mount('#app')

export default vm
