<template>
  <div class="mask" :class="{close: closeState}">
    <div class="my-layer" :style="{padding}">
      <div class="close-btn" @click="close" title="关闭"><i class="el-icon-close"></i></div>
      <h3 class="panel-title">{{ title }}</h3>
      <component :is="component" @close="close" @handle="handleParent" :layer-info="layerInfo" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ""
    },
    component: Object,
    layerInfo: {
      type: Object,
      default: () => {}
    },
    padding: {
      type: String,
      default: () => "20px 32px"
    }
  },

  data() {
    return {
      closeState: false
    }
  },

  methods: {
    close() {
      this.closeState = true
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    },

    handleParent(data) {
      this.$emit('handle', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "MyLayer";
</style>
