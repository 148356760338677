<template>
  <div class="admin" v-loading="$store.state.isLoading" element-loading-background="rgba(255, 255, 255, .58)">
    <header class="header-wrap"><admin-header @update-password="openUpdatePassword" /></header>
    <nav class="nav-wrap"><admin-nav class="nav" :rules="rules" :current="method" @change="changeMethod" /></nav>
    <main class="main-wrap">
      <admin-menus :menus="menus" @open="openPage" v-if="showMenu" />
      <top-tab :page-list="pageList" @change="changePage" @close-page="closePage" />
      <div class="main-view" v-show="!showMenu">
        <component :is="page.componentShow"
                   v-for="(page, index) in pageList"
                   :key="page.id"
                   v-show="index === currentPage"  />
      </div>
    </main>
  </div>

  <my-layer v-if="showUpdatePassword"
             title="修改密码"
             :component="updatePasswordComponent"
             @close="showUpdatePassword = false" />
</template>

<script>
import {shallowRef} from 'vue';

import AdminHeader from "./AdminHeader/AdminHeader";
import AdminNav from "./AdminNav/AdminNav";
import AdminMenus from "./AdminMenus/AdminMenus";
import TopTab from "./TopTab/TopTab";

import menus from "@/data/menus";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {
    AdminHeader,
    AdminNav,
    AdminMenus,
    TopTab,
    MyLayer
  },

  data() {
    return {
      method: "", // 模块名

      rules: [],
      updatePasswordComponent: undefined,
      showUpdatePassword: false,

      showMenu: true, // 显示菜单页
      menus: [], // 菜单列表
      pageList: [], // 标签页列表
      currentPage: -1 // 当前页下标
    }
  },

  async mounted() {
    document.title = '织染星球'
    this.$store.commit("changeLoading", true)
    await this.getUserInfo()
    await this.getNavs()
    await this.getGdAuth()
    this.$store.commit("changeLoading", false)
  },

  methods: {
    async getGdAuth() {
      const res = await this.$api.Base.Department.getUserDeptInfos().catch(() => {})
      this.$store.commit("setIsGd", Reflect.has(res, "data"))
      const departments = res?.data?.userDepts || []
      this.$store.commit("setGdDepartments", departments)
    },

    async getUserInfo() {
      const res = await this.$api.Common.User.getUserInfo().catch(() => {})
      if (!res || !res.usertype) return false
      this.$store.commit('setUserInfo', res)
    },

    // 获取模块
    async getNavs() {
      const nav = await this.$api.Common.Menu.getRootMenu({}).catch(() => {})
      this.rules = menus.getNav(nav)
      await this.changeMethod(this.rules[0])
    },

    // 获取模块下的菜单
    async getMenus(item) {
      this.$showLoading()
      const res = await this.$api.Common.Menu.getChildMenu({parentMenuId: item.id}).catch(() => {})
      this.menus = menus.getMenus(this.method, res, this.$store.state.userinfo.usertype)
      this.$hideLoading()
    },

    // 切换主模块
    async changeMethod(item) {
      if (!item?.name) return false;
      if (this.method === item.name) {
        this.showMenu = true
        return false
      }
      this.method = item.name
      await this.getMenus(item)
      this.showMenu = true
    },

    async openUpdatePassword() {
      this.updatePasswordComponent = shallowRef((await import("./update-password/update-password")).default)
      this.showUpdatePassword = true
    },

    // 打开标签页
    async openPage(page) {
      this.showMenu = false
      let isExist = this.pageList.findIndex(i => i.id === page.id)
      if (isExist < 0) {
        page.componentShow = shallowRef((await page.component()).default)
        this.pageList.push(page)
        this.changePage(this.pageList.length - 1)
      } else {
        this.changePage(isExist)
      }
    },

    // 关闭标签页
    closePage(index) {
      this.pageList.splice(index, 1)
      if (!this.pageList.length) {
        this.showMenu = true
      } else {
        this.changePage(index - 1)
        if (index - 1 <= 0) {
          this.changePage(0)
        }
      }
    },

    // 切换标签页
    changePage(index) {
      this.currentPage = index
      this.showMenu = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./admin";
</style>
