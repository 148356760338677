import api from "@/api"
import store from "@/store"

export default {
  async getDepartments() {
    if (store.state.isGd) {
      const res = await api.Base.Department.getUserDeptInfos().catch(() => {})
      if (res?.code === 200) {
        return res.data.userDepts
      } else {
        this.$message.error(res?.msg || "获取部门列表失败")
      }
    } else {
      const res = await api.Base.Department.getList().catch(() => {})
      if (res?.code === 200) {
        return res.data
      } else {
        this.$message.error(res?.msg || "获取部门列表失败")
      }
    }
  },

  async getStaffs() {
    const res = await api.Base.User.getList({currentPage: 1, pageSize: 10000}).catch(() => {})
    if (res?.code === 200) {
      return res.data.data
    } else {
      this.$message.error(res?.msg || "获取员工列表失败")
    }
  },

  async getProducts() {
    const res = await api.Base.Product.getList({currentPage: 1, pageSize: 10000}).catch(() => {})
    if (res?.code === 200) {
      return res.data.data
    } else {
      this.$message.error(res?.msg || "获取产品列表失败")
    }
  },

  async getSuppliers() {
    const res = await api.Base.Supplier.getList({currentPage: 1, pageSize: 10000}).catch(() => {})
    if (res?.code === 200) {
      return res.data.data
    } else {
      this.$message.error(res?.msg || "获取供应商列表失败")
    }
  },

  async getUnits() {
    const res = await api.Base.Unit.getList().catch(() => {})
    if (res?.code === 200) {
      return res.data
    } else {
      this.$message.error(res?.msg || "获取单位列表失败")
    }
  },
}
