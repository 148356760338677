import axios from 'axios';
import get from 'lodash/get';
import NProgress from "nprogress";
// import router from "@/router";
import store from "@/store";
import App from "@/main"

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  NProgress.done();
  const status = get(error, 'response.status');
  switch (status) {
    case 400: error.message = '请求错误'; break;
    case 401: error.message = '未授权，请登录'; break;
    case 403: error.message = '拒绝访问'; break;
    case 404: error.message = `请求地址出错: ${error.response.config.url}`; break;
    case 408: error.message = '请求超时'; break;
    case 500: error.message = '服务器内部错误'; break;
    case 501: error.message = '服务未实现'; break;
    case 502: error.message = '网关错误'; break;
    case 503: error.message = '服务不可用'; break;
    case 504: error.message = '网关超时'; break;
    case 505: error.message = 'HTTP版本不受支持'; break;
    default:break;
  }
  return Promise.reject(error);
};

let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = (ever) => {
  for (let p in pending) {
    if (pending[p].u === ever.url + '&' + ever.method) { //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); // 把这条记录从数组中移除
    }
  }
}

// request interceptor
request.interceptors.request.use((config) => {
  NProgress.start();
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  // eslint-disable-next-line no-param-reassign
  config.headers.token = `${store.state.token}`;

  removePending(config); //在一个ajax发送前执行一下取消操作
  config.cancelToken = new cancelToken((c) => {
    // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
    pending.push({u: config.url + '&' + config.method, f: c});
  });

  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  NProgress.done();
  const dataAxios = response.data;
  // 这个状态码是和后端约定的
  const {code} = dataAxios;
  // 根据 code 进行判断
  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口
    return dataAxios;
    // eslint-disable-next-line no-else-return
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (+code) {
      case 200:
        return dataAxios;
      case 206:
        App.$message.error(dataAxios.msg);
        return dataAxios;
      case 301:
        sessionStorage.setItem('req_code', '301')
        store.dispatch("logout")
        // router.push('/')
        break;
      default:
        // 不是正确的 code
        // return '不是正确的code';
        return dataAxios;
    }
  }
}, errorHandler);

export default request;
