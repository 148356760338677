import permission from "./permission";
import debounce from "./debounce";

const directives = {
  permission,
  debounce
}

export default {
  install(app) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key])
    })
  }
}