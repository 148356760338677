import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.get(`${base.baseUrl}/platform/customer/getCustomers`, { params }),
  // 获取详情
  getMerchant: id => request.get(`${base.baseUrl}/platform/customer/getCustomersMenu/${id}`),
  // 新增
  add: params => request.post(`${base.baseUrl}/platform/customer/insertCustomer`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/platform/customer/updateCustomersMenu`, params)
}