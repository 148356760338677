import Department from "./department"
import Supplier from "./supplier"
import Unit from "./unit"
import Process from "./process"
import Product from "./product"
import User from "./user"
import Auth from "./auth"

export default {
  Department,
  Supplier,
  Unit,
  Process,
  Product,
  User,
  Auth
}
