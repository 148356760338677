<template>
  <admin v-if="$store.state.isLogin" />
  <login v-else />
</template>

<script>
import Login from "@/views/login/login.vue";
import Admin from "@/views/admin/admin.vue";

export default {
  components: {Admin, Login},
  mounted() {
    const expire = localStorage.getItem("expire")
    const expire_in = localStorage.getItem("expire_in")
    const token = localStorage.getItem("token")
    if (Date.now() < (+expire + (+expire_in * 1000)) && token) {
      this.$store.dispatch("login", localStorage.getItem("token"))
    } else {
      this.$store.dispatch("logout")
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/app.scss";
</style>
