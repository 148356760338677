import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: () => request.get(`${base.baseUrl}/base/department/getDepartment`),
  // 获取跟单员的部门列表
  getUserDeptInfos: () => request.get(`${base.baseUrl}/login-app/getUserDeptInfos`),
  // 新增
  add: params => request.post(`${base.baseUrl}/base/department/insertDepartment`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/base/department/updateDepartment`, params),
  // 删除
  del: id => request.post(`${base.baseUrl}/base/department/deleteDepartment`, {id}),
}
