import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.post(`${base.baseUrl}/progress/getProgress`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/progress/updateProgress`, params),
  // 删除
  del: id => request.post(`${base.baseUrl}/progress/deleteProgress/${id}`)
}
