import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.get(`${base.baseUrl}/base/product/getProducts`, { params }),
  // 获取详情
  getDetail: productId => request.get(`${base.baseUrl}/base/product/getProduct/${productId}`),
  // 新增
  add: params => request.post(`${base.baseUrl}/base/product/insertProduct`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/base/product/updateProduct`, params),
  // 删除
  del: productId => request.get(`${base.baseUrl}/base/product/deleteProduct/${productId}`)
}
