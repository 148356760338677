import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取流程
  getList: params => request.get(`${base.baseUrl}/base/process-parent/getProcess`, { params }),
  // 新增
  add: params => request.post(`${base.baseUrl}/base/process-parent/insertProcess`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/base/process-parent/updateProcess`, params)
}
