import { createStore } from 'vuex'

export default createStore({
  state: {
    departmentId: null,
    isGd: false,
    gdDepartments: [],
    isLoading: false,
    isLogin: false,
    token: "",
    userinfo: {}
  },
  mutations: {
    changeLoading(state, flag) {
      state.isLoading = flag
    },
    changeLogin(state, flag) {
      state.isLogin = flag
    },
    setDepartmentId(state, departmentId) {
      state.departmentId = departmentId
    },
    setIsGd(state, isGd) {
      state.isGd = isGd
    },
    setGdDepartments(state, gdDepartments) {
      state.gdDepartments = gdDepartments
    },
    setUserInfo(state, userinfo) {
      state.userinfo = userinfo
    },
    setUpdateUser(state) {
      state.updateUser = true
      setTimeout(() => {
        state.updateUser = false
      }, 200)
    }
  },
  actions: {
    login({state, commit}, token) {
      commit("changeLogin", true)
      state.token = token
    },
    logout({state, commit}) {
      commit("changeLogin", false)
      state.token = ""
      state.departmentId = null
      state.isGd = false
      state.gdDepartments = []
      localStorage.clear()
    }
  },
  modules: {
  }
})
