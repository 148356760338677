import request from "@/libs/axios";
import base from "@/api/base";

export default {
  // 获取列表
  getList: params => request.post(`${base.baseUrl}/incoming/getIncomingList`, params),
  // 新增
  add: params => request.post(`${base.baseUrl}/incoming/insertIncoming`, params),
  // 修改
  update: params => request.post(`${base.baseUrl}/incoming/updateIncoming`, params),
  // 删除
  del: id => request.post(`${base.baseUrl}/incoming/deleteIncoming/${id}`)
}
